import React, { CSSProperties } from 'react'
import ReactDOM from 'react-dom'
import CloseIcon from '@/public/IconClose.svg'

class Modal extends React.Component<Props> {
  modal = React.createRef<HTMLDivElement>()

  componentDidMount() {
    document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto'
  }

  render() {
    const size = this.props.size || 'md'

    let modalClass = 'app-modal is-' + size
    if (this.props.isTall) modalClass += ' is-tall'

    let shadowClass = 'app-modal__shadow'
    if (this.props.shouldAnimate !== false) shadowClass += ' app-modal-animation'

    const body = document.getElementsByTagName('body')[0]

    const handleClose = () => {
      this.props.onClose()
    }

    // в случае если родительский контейнер компонента имеет css трансформацию, то она
    // будет нарушать позиционирование модального окна, поэтому выносим его из текущего
    // положения в дереве в <body> при помощи portals
    return (
      <>
        {ReactDOM.createPortal(
          <div
            className={shadowClass}
            style={size === 'sm' ? undefined : { maxHeight: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <div ref={this.modal} className={modalClass} style={this.props.style}>
              {this.props.withCloseIcon !== false && (
                <div className="app-modal__close-icon" onClick={handleClose}>
                  <CloseIcon />
                </div>
              )}
              {this.props.children}
            </div>
          </div>,
          body
        )}
      </>
    )
  }
}

interface Props {
  size?: 'sm' | 'md' | 'lg' | 'hg'
  isTall?: boolean
  onClose: () => void
  closeOnClickOutside?: boolean // default true
  closeOnEscape?: boolean // default true
  shouldAnimate?: boolean
  style?: CSSProperties
  children: any
  withCloseIcon?: boolean
}

export default Modal
