import { INews } from '@/components/News/news.types'
import css from './newsTile.module.css'
import timeUtils from '@/utils/time.utils'
import { CSSProperties } from 'react'
import ReactMarkdown from 'react-markdown'

interface IProps {
  news: INews
  styles?: CSSProperties
}

const NewsTile = (props: IProps) => {
  return (
    <div className={css.wrapper} style={props.styles} data-test="news" data-test-id={props.news.id}>
      <h2 className="text-700 text-16px text--default" style={{ lineHeight: '26px' }} data-test="news-title">
        {props.news.title}
      </h2>
      <p className="text-14px text--default" style={{ lineHeight: '22px' }} data-test="news-content">
        <ReactMarkdown className="text-14px">{props.news.content}</ReactMarkdown>
      </p>
      <span className="text-12px text--grey" style={{ display: 'block', marginTop: '5px' }} data-test="news-date">
        {timeUtils.formatDate(new Date(props.news.updated ?? props.news.created), 'dd MMM yyyy')}
      </span>
    </div>
  )
}

export default NewsTile
