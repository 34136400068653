import { MutableRefObject, RefObject, useCallback, useEffect, useRef } from 'react'

export function usePreventVerticalScroll<T extends HTMLElement>(
  ref: RefObject<T>,
  shouldPrevent: MutableRefObject<boolean>,
  dragThreshold = 5
) {
  const firstClientX = useRef<number>(0)
  const clientX = useRef<number>(0)

  const preventTouch = useCallback(
    (e: TouchEvent) => {
      if (!shouldPrevent.current) return

      clientX.current = e.touches[0].clientX - firstClientX.current

      if (Math.abs(clientX.current) > dragThreshold) {
        e.preventDefault()
        return false
      }

      return true
    },
    [dragThreshold, shouldPrevent]
  )

  const touchStart = useCallback((e: TouchEvent) => {
    firstClientX.current = e.touches[0].clientX
  }, [])

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('touchstart', touchStart)
      ref.current.addEventListener('touchmove', preventTouch, { passive: false })
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('touchstart', touchStart)
        ref.current.removeEventListener('touchmove', preventTouch)
      }
    }
  }, [preventTouch, ref, touchStart])
}

export default usePreventVerticalScroll
