import useCampaigns from '@/components/Goods/Campaigns/useCampaigns'
import Card from '@/shared/Cards/Card'
import css from './mainWaitlist.module.css'
import BoxIcon from '@/public/box.svg'
import ArrowRight from '@/public/IconForward.svg'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'

const ActiveWaitlistMainBlock = () => {
  const intl = useIntl()
  const router = useRouter()
  const { campaigns, loading } = useCampaigns()

  if (loading) return null
  const activeCampaign = campaigns.find((c) => c.waitlist?.status === 'OPEN' && !c.waitlist.joined)
  const activeWaitlist = activeCampaign?.waitlist
  if (!activeWaitlist) return null

  const cardContent = (
    <div
      className="inline-flex"
      style={{ alignItems: 'center', justifyContent: 'space-between', gap: '16px' }}
      onClick={() => router.push('/Waitlist/Join/' + activeCampaign.id)}
    >
      <BoxIcon style={{ flexShrink: 0 }} />
      <div className="text--white text-bold">
        {intl.formatMessage({ id: 'purchase.join_closest_waitlist' })} <span>{activeCampaign.title}</span>
      </div>
      <ArrowRight style={{ flexShrink: 0, marginLeft: '6px' }} />
    </div>
  )
  return (
    <div className="sides-padding" style={{ marginBottom: '20px' }}>
      <Card className={css.wrapper} description={cardContent} />
    </div>
  )
}

export default ActiveWaitlistMainBlock
