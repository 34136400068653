import http, { handleHttpError, handleHttpResponse } from '@/shared/http'
import { HTTPResponse } from '@/types/http.types'
import { ICampaign } from '@/components/Goods/Campaigns/campaigns.types'
import { ICountry } from '@/components/Shipping/Countries/countries.types'

const getCampaigns = (): Promise<HTTPResponse<ICampaign[]>> => {
  return http.get('/api/v1/campaigns/').then(handleHttpResponse).catch(handleHttpError)
}

interface IJoinWaitlistData {
  comments: string
  country: ICountry['id']
  desiredQuantity: number
  shippingMethod: string | null
}

const joinWaitlist = (id: ICampaign['id'], data: IJoinWaitlistData): Promise<HTTPResponse<{ status: string }>> => {
  return http.post(`/api/v1/campaigns/${id}/join_waitlist/`, data).then(handleHttpResponse).catch(handleHttpError)
}

const campaignsApi = { getCampaigns, joinWaitlist }
export default campaignsApi
