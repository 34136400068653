import { HTTPResponse } from '@/types/http.types'
import http, { handleHttpError, handleHttpResponse } from '@/shared/http'
import { IOrder } from '@/components/Purchase/components/Orders/orders.types'

class OrdersService {
  private loading = false
  public loaded = false

  async getOrders(): Promise<HTTPResponse<IOrder[]>> {
    if (this.loading || this.loaded) return

    this.loading = true

    const resp = await http
      .get('/api/v1/me/orders/?expand=product,recipient,recipient.country,recipient.province')
      .then(handleHttpResponse)
      .catch(handleHttpError)
    if (resp.status === 'success') resp.body = resp.body.results

    this.loaded = true
    this.loading = false

    return resp
  }
}

const ordersService = new OrdersService()

export default ordersService
