import useHttpLoader from '@/hooks/useHttpLoader'
import { useRecoilState } from 'recoil'
import newsAtom from '@/components/News/news.atom'
import { useEffect } from 'react'
import newsApi from '@/components/News/news.api'

const useNews = () => {
  const { wait, loading } = useHttpLoader()
  const [news, setNews] = useRecoilState(newsAtom)

  useEffect(() => {
    if (news.loaded) return

    wait(newsApi.getNews(), (resp) => {
      if (resp.status === 'success') setNews({ news: resp.body.results, loaded: true })
    })
  }, [news])

  return { loading: loading || !news.loaded, news: news.news }
}

export default useNews
