import { IPassportData, IRecipient } from '@/components/Shipping/Recipients/recipient.types'

const createEmptyRecipient = (withPassport = true): IRecipient => ({
  id: '',
  firstName: '',
  middleName: '',
  lastName: '',
  address1: '',
  phone: '',
  zipCode: '',
  province: '',
  city: '',
  country: '',
  passport: withPassport
    ? {
        firstName: '',
        lastName: '',
        middleName: '',
        dateOfBirth: '', // yyyy-mm-dd
        series: '',
        number: '',
        dateOfIssue: '', // yyyy-mm-dd
        organization: '',
        tin: '',
        type: 'RussianPassport',
        scan: null,
      }
    : null,
})

const getPassportFieldValue = <T extends keyof IPassportData>(recipient: IRecipient, key: T): IPassportData[T] => {
  return recipient.passport?.[key] ?? ('' as IPassportData[T])
}

const extractFullName = (recipient: Pick<IRecipient, 'firstName' | 'lastName' | 'middleName'>) => {
  return `${recipient.lastName} ${recipient.firstName} ${recipient.middleName || ''}`
}

const extractFullAddress = (recipient: Pick<IRecipient, 'country' | 'zipCode' | 'province' | 'city' | 'address1'>) => {
  return `${recipient.country}, ${recipient.zipCode}, ${recipient.province ? recipient.province + ', ' : ''}${
    recipient.city
  }, ${recipient.address1}`
}

const recipientUtils = { createEmptyRecipient, extractFullName, extractFullAddress, getPassportFieldValue }
export default recipientUtils
