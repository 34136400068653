import { useRecoilState } from 'recoil'
import { useEffect } from 'react'
import useHttpLoader from '@/hooks/useHttpLoader'
import ordersAtom from '@/components/Purchase/components/Orders/orders.atom'
import ordersService from '@/components/Purchase/components/Orders/orders.service'

const useOrders = () => {
  const { wait, loading } = useHttpLoader()
  const [orders, setOrders] = useRecoilState(ordersAtom)

  useEffect(() => {
    wait(ordersService.getOrders(), (r) => {
      if (r && r.status === 'success') setOrders(r.body)
    })
  }, [])

  return { orders, loading: loading || !ordersService.loaded }
}

export default useOrders
