import useNews from '@/components/News/components/hooks/useNews'
import LoaderPage from '@/shared/Loaders/LoaderPage/LoaderPage'
import { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'

const NewsProvider = (props: PropsWithChildren<{}>) => {
  const intl = useIntl()
  const { loading } = useNews()

  if (loading) return <LoaderPage inscription={intl.formatMessage({ id: 'news_loading' })} />
  return <>{props.children}</>
}

export default NewsProvider
