import recipientUtils from '@/components/Shipping/Recipients/recipient.utils'
import orderPicSrc from '@/public/miniBox.png'
import { IOrder } from '@/components/Purchase/components/Orders/orders.types'
import { memo } from 'react'
import timeUtils from '@/utils/time.utils'
import ordersUtils from '@/components/Purchase/components/Orders/orders.utils'
import OrderStatus from '@/components/Purchase/components/Orders/components/OrderCard/OrderStatus'
import Image from '@/shared/Image'

interface IProps {
  onSelect: (id: IOrder['id']) => void
  order: IOrder
}

const OrderCard = (props: IProps) => {
  const handleClick = () => {
    props.onSelect(props.order.id)
  }

  return (
    <div className="etba-card inline-flex" onClick={handleClick} style={{ cursor: 'pointer' }}>
      <div className="flex-form">
        <h2 className="text-700 text-success text-14px">{props.order.number}</h2>
        <h2 className="text-700 text--primary">{props.order.product.title}</h2>
        <span className="text-14px" style={{ marginTop: '-0.75em' }}>
          {timeUtils.formatDate(new Date(props.order.created), 'dd MMM yyyy')}
        </span>
        <span className="text-700 text--default">
          {ordersUtils.getPriceCurrencySign(props.order.product.priceCurrency)} {props.order.paymentTotal}
        </span>
        <span className="text-14px text--default">
          {recipientUtils.extractFullName(props.order.recipient)}
          <br />
          {props.order.recipient.city}
        </span>
        <OrderStatus order={props.order} />
      </div>
      <div style={{ width: '80px', position: 'relative', height: '80px' }}>
        <Image
          src={props.order.product?.image ?? orderPicSrc}
          width={100}
          height={100}
          alt="Product"
          unoptimized={true}
        />
      </div>
    </div>
  )
}

export default memo(OrderCard)
