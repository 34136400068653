const openLink = (to: string) => {
  const l = document.createElement('a')
  l.href = to
  l.target = '_blank'
  l.click()
}

const domUtils = {
  openLink,
}

export default domUtils
