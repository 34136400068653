import { memo, ReactNode, useCallback, useEffect, useRef } from 'react'
import css from './slider.module.css'
import ArrowRight from '@/public/arrowRight.svg'
import useSwipe from '@/hooks/useSwipe'
import usePreventVerticalScroll from '@/hooks/usePreventVerticalScroll'
import swipeIconSrc from '@/public/swipe.png'
import usePersistentState from '@/hooks/usePersistentState'

interface IProps {
  slides: ReactNode[] | JSX.Element[]

  slide: number
  onPrev: () => void
  onNext: () => void
  onSelect: (slide: number) => void
  onSlideClick?: (slide: number) => void

  pointsVisible?: boolean
  slideCn?: string
  showTooltip?: boolean
  arrowsVisible?: boolean
}

const Slider = (props: IProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [showTooltip, setShowTooltip] = usePersistentState<boolean>(props.slides.length > 1, 'sliderTooltipShown')

  const onSwipeRight = useCallback(() => {
    setShowTooltip(false)
    props.onNext()
  }, [props.onNext])

  const onSwipeLeft = useCallback(() => {
    setShowTooltip(false)
    props.onPrev()
  }, [props.onPrev])

  const handleRotateX = useCallback(
    (rotateX: number) => {
      const transform = `translate3d(${getTransformXPercent(props.slide, props.slides.length, rotateX)}, 0, 0)`
      const transitionDuration = rotateX ? '0ms' : '400ms'

      ref.current.style.transform = transform
      ref.current.style.transitionDuration = transitionDuration
    },
    [props.slide]
  )

  useEffect(() => {
    const transform = `translate3d(${getTransformXPercent(props.slide, props.slides.length, 0)}, 0, 0)`
    ref.current.style.transform = transform
  }, [props.slide])

  const { handlers, isSwiping } = useSwipe({
    onSwipeRight,
    onSwipeLeft,
    onRotate: handleRotateX,
  })

  usePreventVerticalScroll(ref, isSwiping)

  const renderPoints = () => {
    return props.slides.map((_, ind) => <span key={ind} className={css.point} data-selected={ind === props.slide} />)
  }

  const renderSlides = () => {
    return props.slides.map((s, index) => (
      <div
        className={props.slideCn ?? css.slide}
        key={index}
        data-test="slide_item"
        data-test-index={index}
        onClick={() => props.onSlideClick && props.onSlideClick(index)}
      >
        {s}
      </div>
    ))
  }

  return (
    <div className={css.wrapper} {...handlers} data-test="slider">
      {props.showTooltip !== false && (
        <div className={css.tooltip} style={{ background: `url("${swipeIconSrc.src}")` }} data-visible={showTooltip} />
      )}
      {props.slides.length > 1 && props.arrowsVisible !== false && (
        <ArrowRight className={css.arrowLeft} onClick={props.onPrev} data-test="slider_left" />
      )}
      <div className={css.scrollContent}>
        <div ref={ref} className={css.slides}>
          {renderSlides()}
        </div>
      </div>
      {props.slides.length > 1 && props.pointsVisible !== false && <div className={css.points}>{renderPoints()}</div>}
      {props.slides.length > 1 && props.arrowsVisible !== false && (
        <ArrowRight className={css.arrowRight} onClick={props.onNext} data-test="slider_right" />
      )}
    </div>
  )
}

const getTransformXPercent = (slide: number, slidesCount: number, offset = 0): string => {
  const movePercent = slide * 80 + '%'
  let isLastOrFirstInc = 0
  if (slide === 0) isLastOrFirstInc = 0
  else if (slide === slidesCount - 1) isLastOrFirstInc = 55
  else isLastOrFirstInc = 25

  let normalizedOffset = offset
  if (slide === 0) {
    normalizedOffset = Math.min(100, offset)
  }
  if (slide === slidesCount - 1) normalizedOffset = Math.max(normalizedOffset, -100)

  return `calc(-${movePercent} ${
    offset >= 0 ? `+ ${normalizedOffset}` : `- ${-normalizedOffset}`
  }px + ${isLastOrFirstInc}px)`
}

export default memo(Slider)
