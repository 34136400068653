import { useRecoilState } from 'recoil'
import { useEffect, useState } from 'react'
import campaignsService from '@/components/Goods/Campaigns/campaigns.service'
import campaignsAtom from '@/components/Goods/Campaigns/campaigns.atom'
import useHttpLoader from '@/hooks/useHttpLoader'
import { useRouter } from 'next/router'
import { integrationSignUpConfig } from '@/shared/constants'
import produce from 'immer'

const useCampaigns = () => {
  const { wait, loading } = useHttpLoader()
  const query = useRouter().query
  const code = query['code']

  const [error, setError] = useState('')
  const [campaigns, setCampaigns] = useRecoilState(campaignsAtom)

  useEffect(() => {
    if (campaignsService.loaded || loading || campaigns.length > 0) return

    wait(campaignsService.getCampaigns(), (r) => {
      if (r && r.status === 'success') {
        setCampaigns(
          produce(r.body, (draft) => {
            const isAllowedToJoin = integrationSignUpConfig.code === code
            const campaignIndex = draft.findIndex((c) => c.id === integrationSignUpConfig.campaignId)
            if (campaignIndex === -1) return draft

            draft[campaignIndex].waitlist.status = isAllowedToJoin ? 'OPEN' : 'CLOSED'
            return draft
          })
        )
      }
      if (r && r.status === 'error') setError(r.message)
    })
  }, [])

  return { campaigns, error, loading: loading || !campaignsService.loaded }
}

export default useCampaigns
