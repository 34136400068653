import { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import cn from '@/shared/cn'

interface ISelectableCardProps {
  description?: string | ReactNode | ReactNode[]
  onClick?: () => void
  className?: string
  styles?: CSSProperties
}

const Card = (props: PropsWithChildren<ISelectableCardProps>) => {
  const renderDescription = () => {
    if (!props.description) return null

    return <div className="etba-card-description">{props.description}</div>
  }

  const normalizedExternalStyle = props.styles ?? {}
  const normalizedCn = cn('etba-card', props.className ?? '')
  return (
    <div
      className={normalizedCn}
      onClick={props.onClick}
      style={{ ...normalizedExternalStyle, cursor: props.onClick ? 'pointer' : undefined }}
    >
      {props.children && <div className="etba-card-header">{props.children}</div>}
      {renderDescription()}
    </div>
  )
}

export default Card
