import { ICampaign } from '@/components/Goods/Campaigns/campaigns.types'
import Button from '@/shared/Button/Button'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import { useState } from 'react'
import ConfirmBuyModal from './ConfirmBuyModal'

interface IProps {
  campaign: ICampaign
}

const CampaignActionBtn = ({ campaign }: IProps) => {
  const intl = useIntl()
  const router = useRouter()

  const confirmModalRequired = campaign.type === 'PRE_ORDER'
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)

  const isFinished = campaign.status === 'FINISHED'
  const isForSale = campaign.availableForSale
  const isForWaitlist =
    (campaign.waitlist?.status === 'OPEN' && campaign.status === 'ANNOUNCE') || campaign.waitlist?.joined

  const handleBuy = (confirmed?: boolean) => {
    if (confirmModalRequired && !confirmed) {
      return setConfirmModalOpen(true)
    }

    router.push('/Buy/Campaign/' + campaign.id)
  }

  if (isForWaitlist && !isFinished && !isForSale) {
    if (campaign.waitlist.joined) {
      return (
        <Button theme="success-dashed" disabled={true} data-test-campaign_id={campaign.id}>
          {intl.formatMessage({ id: 'purchase.waitlist_joined_btn' })}
        </Button>
      )
    }

    return (
      <Button
        theme="success"
        onClick={() => router.push('/Waitlist/Join/' + campaign.id)}
        glare={true}
        data-test-campaign_id={campaign.id}
      >
        {intl.formatMessage({ id: 'purchase.join_waitlist' })}
      </Button>
    )
  }

  if (isForSale) {
    return (
      <>
        {confirmModalOpen && (
          <ConfirmBuyModal
            onConfirm={() => handleBuy(true)}
            onClose={() => setConfirmModalOpen(false)}
            campaign={campaign}
          />
        )}
        <Button
          theme={campaign.type === 'PRE_ORDER' ? 'orange' : 'primary'}
          onClick={() => handleBuy()}
          glare={true}
          data-test-campaign_id={campaign.id}
          data-action="buy"
        >
          {campaign.type === 'PRE_ORDER' ? 'Сделать Предзаказ' : intl.formatMessage({ id: 'purchase.buy' })}
        </Button>
      </>
    )
  }

  if (isFinished) {
    return (
      <Button theme="grey" disabled={true} data-test-campaign_id={campaign.id}>
        {intl.formatMessage({ id: 'purchase.sold_out' })}
      </Button>
    )
  }

  return (
    <Button theme="grey" disabled={true} data-test-campaign_id={campaign.id}>
      {intl.formatMessage({ id: 'purchase.coming_soon' })}
    </Button>
  )
}

export default CampaignActionBtn
