import { HTTPResponse, IPaginationResponse } from '@/types/http.types'
import { INews } from '@/components/News/news.types'
import http, { handleHttpError, handleHttpResponse } from '@/shared/http'

const getNews = (): Promise<HTTPResponse<IPaginationResponse<INews[]>>> => {
  return http.get('/api/v1/news/').then(handleHttpResponse).catch(handleHttpError)
}

const newsApi = { getNews }
export default newsApi
