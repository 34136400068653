import { HTTPResponse } from '@/types/http.types'
import { ICampaign } from '@/components/Goods/Campaigns/campaigns.types'
import campaignsApi from '@/components/Goods/Campaigns/campaigns.api'

class CampaignsService {
  private loading = false
  public loaded = false

  async getCampaigns(): Promise<HTTPResponse<ICampaign[]>> {
    if (this.loading || this.loaded) return

    this.loading = true

    const resp = await campaignsApi.getCampaigns()

    this.loaded = true
    this.loading = false

    return resp
  }
}

const campaignsService = new CampaignsService()

export default campaignsService
