import { Dispatch, useState, SetStateAction } from 'react'

/**
 * Replicate useState with the localStorage
 */
const usePersistentState = <T extends Object | Array<unknown> | Set<unknown> | number | string>(
  initialState: T,
  stateKey: string
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(JSON.parse(localStorage.getItem(stateKey)) ?? initialState)

  const setBothStates: Dispatch<SetStateAction<T>> = (arg) => {
    const updates = typeof arg === 'function' ? arg(state) : arg

    setState(updates)
    localStorage.setItem(stateKey, JSON.stringify(updates))
  }

  return [state, setBothStates]
}

export default usePersistentState
