import { ICampaign } from '@/components/Goods/Campaigns/campaigns.types'

const translateStatus = (
  status: ICampaign['status'],
  waitlistStatus: ICampaign['waitlist']['status'],
  campaignType: ICampaign['type']
) => {
  if (campaignType === 'PRE_ORDER' && status === 'PUBLIC_SALE') {
    return 'purchase.campaign.status.pre_order'
  }

  switch (true) {
    case status === 'FINISHED':
      return 'purchase.campaign.status.finished'
    case waitlistStatus === 'OPEN' && status === 'ANNOUNCE':
      return 'purchase.campaign.status.waitlist_join'
    case status === 'PUBLIC_SALE':
      return 'purchase.campaign.status.public_sale'
    case status === 'PRIVATE_SALE':
    case status === 'ANNOUNCE':
      return 'purchase.campaign.status.announce'
    case status === 'WAITLIST_SALE':
      return 'purchase.campaign.status.closed_sale'
    case status === 'DRAFT':
      return '?'
  }
}

const campaignUtils = { translateStatus }

export default campaignUtils
