import useNews from '@/components/News/components/hooks/useNews'
import NewsCard from '@/components/News/components/NewsCard'
import Button from '@/shared/Button/Button'
import NotificationsIcon from '@/public/notifications.svg'
import css from './mainNewsBlock.module.css'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'

const MainNewsBlock = () => {
  const intl = useIntl()
  const router = useRouter()
  const { news } = useNews()

  if (!news.length) return null

  return (
    <div>
      <div className="inline-flex flex-space-between" style={{ marginBottom: '20px' }}>
        <h2 className="text--grey text-road text-18px">{intl.formatMessage({ id: 'news.news_heading' })}</h2>
        <NotificationsIcon className={css.notificationsIcon} onClick={() => router.push('/News')} />
      </div>
      <NewsCard news={news[0]} />
      <div style={{ marginTop: '30px' }}>
        <Button theme="grey" onClick={() => router.push('/News')}>
          {intl.formatMessage({ id: 'show_all' })}
        </Button>
      </div>
    </div>
  )
}

export default MainNewsBlock
