import { IOrder } from '@/components/Purchase/components/Orders/orders.types'

const getPriceCurrencySign = (currency: IOrder['product']['priceCurrency']) => {
  switch (currency) {
    case 'RUB':
      return '₽'
    case 'USD':
      return '$'
    default:
      return currency
  }
}

const ordersUtils = { getPriceCurrencySign }
export default ordersUtils
