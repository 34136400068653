import { IOrder } from '@/components/Purchase/components/Orders/orders.types'
import AlertIcon from '@/public/alert.svg'
import css from './customsWarningBanner.module.css'
import { useRouter } from 'next/router'

interface IProps {
  orders: IOrder[]
}

const CustomsWarningBanner = (props: IProps) => {
  const router = useRouter()
  const bannerVisible = props.orders.some((o) => o.passportScanRequired && !o.recipient.passport?.scan)

  if (!bannerVisible) return null

  return (
    <div className={css.flexWrapper}>
      <div className={css.wrapper} onClick={() => router.push('/customs')}>
        <div className="text-14px">
          <h2 className="text-bold">Внимание!</h2>
          <span>{'Требуется ввести паспортные данные для таможенного оформления >>>'}</span>
        </div>
        <AlertIcon style={{ flexShrink: 0 }} />
      </div>
    </div>
  )
}

export default CustomsWarningBanner
