import NewsTile from '@/components/News/components/NewsTile'
import { INews } from '@/components/News/news.types'

interface IProps {
  news: INews
}

const NewsCard = (props: IProps) => {
  return (
    <div style={{ boxShadow: '0px 5px 30px rgba(44, 92, 250, 0.1)', borderRadius: '8px' }} data-test="news-block">
      <NewsTile news={props.news} />
    </div>
  )
}

export default NewsCard
