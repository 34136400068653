import { useCallback, useState } from 'react'

const useSlider = (slidesCount: number) => {
  const [slide, setSlide] = useState(0)

  const nextSlide = useCallback(() => setSlide((prev) => Math.min(slidesCount - 1, prev + 1)), [slidesCount])
  const prevSlide = useCallback(() => setSlide((prev) => Math.max(0, prev - 1)), [])

  return { slide, nextSlide, prevSlide, onSelect: setSlide }
}

export default useSlider
