import Modal from '@/shared/Modal'
import { ICampaign } from '../../../campaigns.types'
import { formatDate } from '@/utils/time.utils'
import Button from '@/shared/Button/Button'

interface IProps {
  onClose: () => void
  onConfirm: () => void
  campaign: ICampaign
}

const ConfirmBuyModal = (props: IProps) => {
  return (
    <Modal size="sm" onClose={props.onClose}>
      <div className="flex-form" style={{ marginTop: '26px' }}>
        <p style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
          Обращаем ваше внимание, что на данный товар действует предазказ. Его можно приобести сейчас, но он будет
          отправлен{'\n'}
          <span className="text--bold" style={{ fontWeight: 'bold' }}>
            {formatDate(new Date(props.campaign.dispatchDate), 'dd MMM yyyy')}
          </span>
        </p>
        <Button theme="primary" onClick={props.onConfirm}>
          Продолжить
        </Button>
        <Button theme="grey" onClick={props.onClose}>
          Отменить
        </Button>
      </div>
    </Modal>
  )
}

export default ConfirmBuyModal
