import css from '@/components/Purchase/components/Orders/components/OrderCard/oderCard.module.css'
import { IOrder } from '@/components/Purchase/components/Orders/orders.types'
import { useIntl } from 'react-intl'

interface IProps {
  order: IOrder
}

const OrderStatus = (props: IProps) => {
  const intl = useIntl()

  const getInscription = () => {
    return intl.formatMessage({ id: 'purchase.order.status.' + props.order.status.toLowerCase() })
  }

  return (
    <div
      className={css.status}
      data-normal={props.order.status === 'NEW'}
      data-active={props.order.status === 'FULFILLED' || props.order.status === 'DONE'}
      data-failure={props.order.status === 'CANCELED'}
    >
      <span
        className={css.circle}
        data-normal={props.order.status === 'NEW'}
        data-active={props.order.status === 'FULFILLED' || props.order.status === 'DONE'}
        data-failure={props.order.status === 'CANCELED'}
      />
      <span className="text-11px text-400">{getInscription()}</span>
    </div>
  )
}

export default OrderStatus
