import { TouchEvent, useRef, useCallback, MouseEvent, useEffect } from 'react'

interface IProps {
  onSwipeLeft: () => void
  onSwipeRight: () => void
  onRotate: (value: number) => void
}

const useSwipe = (props: IProps) => {
  const touchXStart = useRef(-1)
  const touchYStart = useRef(0)
  const touchEnd = useRef(-1)
  const isSwiping = useRef(false)

  const onTouchStart = useCallback((e: TouchEvent<HTMLDivElement>) => {
    isSwiping.current = true
    touchXStart.current = e.targetTouches[0].clientX
    touchYStart.current = e.targetTouches[0].clientY
  }, [])

  const onTouchMove = useCallback(
    (e: TouchEvent<HTMLDivElement>) => {
      if (
        Math.abs(touchYStart.current - e.targetTouches[0].clientY) >=
          Math.abs(touchXStart.current - e.targetTouches[0].clientX) ||
        Math.abs(touchXStart.current - e.targetTouches[0].clientX) < 5
      ) {
        isSwiping.current = false
        return
      }

      e.stopPropagation()

      const ox = e.targetTouches[0].clientX
      touchEnd.current = ox

      props.onRotate(Math.round((ox - touchXStart.current) * 0.45))
    },
    [props.onRotate]
  )

  const onTouchEnd = useCallback(() => {
    const ox = touchEnd.current
    if (Math.abs(ox - touchXStart.current) > 100 && touchXStart.current !== -1 && touchEnd.current !== -1) {
      if (ox > touchXStart.current) props.onSwipeLeft()
      else props.onSwipeRight()
    }

    touchXStart.current = -1
    touchEnd.current = -1

    props.onRotate(0)
    isSwiping.current = false
  }, [props.onRotate, props.onSwipeRight, props.onSwipeLeft])

  const onMouseDown = useCallback((ev: MouseEvent<HTMLDivElement>) => {
    ev.preventDefault()

    isSwiping.current = true
    touchXStart.current = ev.clientX
    touchYStart.current = ev.clientY
  }, [])

  const onMouseMove = useCallback(
    (ev: MouseEvent<HTMLDivElement>) => {
      ev.stopPropagation()
      ev.preventDefault()

      if (!isSwiping.current) return

      const ox = ev.clientX
      touchEnd.current = ox

      props.onRotate(Math.round((ox - touchXStart.current) * 0.45))
    },
    [props.onRotate]
  )

  // handle mouse up
  useEffect(() => {
    const handler = (ev) => {
      ev.preventDefault()
      ev.stopPropagation()

      if (!isSwiping.current) return

      const ox = touchEnd.current

      if (Math.abs(ox - touchXStart.current) > 100 && touchXStart.current !== -1 && touchEnd.current !== -1) {
        if (ox > touchXStart.current) props.onSwipeLeft()
        else props.onSwipeRight()
      }

      touchXStart.current = -1
      touchEnd.current = -1

      props.onRotate(0)
      isSwiping.current = false
    }
    document.addEventListener('mouseup', handler)

    return () => {
      document.removeEventListener('mouseup', handler)
    }
  }, [props.onRotate, props.onSwipeRight, props.onSwipeLeft])

  return { isSwiping, handlers: { onTouchStart, onTouchMove, onTouchEnd, onMouseDown, onMouseMove } }
}

export default useSwipe
