import { ICampaign } from '@/components/Goods/Campaigns/campaigns.types'
import campaignLogoUrl from '@/public/campaignLogo.jpg'
import css from './campaignTile.module.css'
import { memo } from 'react'
import campaignUtils from '@/components/Goods/Campaigns/campaign.utils'
import Image from '@/shared/Image'
import { useIntl } from 'react-intl'
import { formatDate } from '@/utils/time.utils'

interface IProps {
  campaign: ICampaign
}

const CampaignTile = ({ campaign }: IProps) => {
  const intl = useIntl()

  if (!campaign) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('CampaignTile: received empty campaign')
    }

    return null
  }

  const renderContent = () => {
    if (campaign.type === 'PRE_ORDER') {
      return (
        <>
          <span
            className={css.campaignTitleWithSubtitle}
            style={{ color: campaign.titleColor || '#4a4a68' }}
            data-test="campaign_title"
          >
            {campaign.title}
          </span>
          <span
            className={css.campaignSubtitle}
            style={{ color: campaign.titleColor || '#4a4a68' }}
            data-test="campaign_title"
          >
            Отправка {formatDate(new Date(campaign.dispatchDate), 'dd MMM yyyy')}
          </span>
          <p
            className={css.campaignStatus}
            data-test="campaign_status"
            style={{ color: campaign.titleColor || '#4a4a68' }}
          >
            {intl.formatMessage({
              id: campaignUtils.translateStatus(campaign.status, campaign.waitlist?.status, campaign.type),
            })}
          </p>
        </>
      )
    }

    return (
      <>
        <span
          className={css.campaignTitle}
          style={{ color: campaign.titleColor || '#4a4a68' }}
          data-test="campaign_title"
        >
          {campaign.title}
        </span>
        <p className={css.campaignStatus} data-test="campaign_status">
          {intl.formatMessage({
            id: campaignUtils.translateStatus(campaign.status, campaign.waitlist?.status, campaign.type),
          })}
        </p>
      </>
    )
  }

  return (
    <div
      className="flex-form"
      style={{ paddingTop: '13px', paddingBottom: '15px' }}
      data-test="campaign"
      data-test-id={campaign.id}
    >
      <div className={`etba-card ${css.card}`} style={{ boxShadow: 'none' }}>
        <Image
          src={campaign.image ?? campaignLogoUrl}
          quality={100}
          priority={true}
          alt="Campaign"
          fill={true}
          unoptimized={true}
          wrapperStyle={{ height: '100%' }}
          style={{ objectFit: 'cover', borderRadius: '4px' }}
        />
        {renderContent()}
      </div>
    </div>
  )
}

export default memo(CampaignTile)
